import React, { useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { PageProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Banner } from '../component/Banner'
import { Layout } from '../component/Layout'
import { LifeStyle } from '../component/page_component/LifeStyle'
import { HighLights } from '../component/page_component/HighLights'
import { PropertyList } from '../component/page_component/PropertyList'
import { SearchContext } from '../contexts/searchContext'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  highlight: {
    marginTop: theme.spacing(15),
    backgroundColor: '#fff',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(40),
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.between('md', 'xl')]: {
      marginTop: theme.spacing(0),
    },
  },
}))

const Buy: React.FC<PageProps> = (props: any) => {
  const {
    location,
    pageContext: { data },
  } = props
  const intl = useIntl()
  const classes = useStyles()

  const { setSearch } = useContext(SearchContext)

  useEffect(() => {
    setSearch({ name: 'transactionType', value: 'buy' })
  }, [])

  const hreflangLinks = hreflangConfig['/fr/buy']

  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleBuy' })}>
      <Helmet>
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
      </Helmet>
      <Banner
        src="buy_banner.jpg"
        innerStyle={{
          zIndex: '1100',
          position: 'relative',
          marginTop: '-150px',
        }}
      ></Banner>
      <Grid container justifyContent="center" id="highlight" className={classes.highlight}>
        <HighLights
          title={intl.formatMessage({ id: 'buy.title' })}
          detail={intl.formatMessage({ id: 'buy.detail' })}
          linkTxt={intl.formatMessage({ id: 'buy.link' })}
          buy={true}
        />
      </Grid>
      <Grid container justifyContent="center" id="lifestyle">
        <LifeStyle />
      </Grid>
      <Grid container justifyContent="center" id="buy-dream-destination">
        <PropertyList
          bgClr="#FAE9E5"
          filterBackgroundColor={'#FFF'}
          data={data}
          location={location}
        />
      </Grid>
    </Layout>
  )
}

export default Buy
